import { RoutePath } from "routes/Route.types"

export const classRoutes = [
  {
    path: RoutePath.CLASS,
    tabName: "students",
    labelKey: "layout.students",
    activeOnPaths: [RoutePath.CLASS],
  },
  {
    path: RoutePath.CLASS_JOBS,
    tabName: "jobs",
    labelKey: "layout.jobs",
    activeOnPaths: [RoutePath.CLASS_JOBS],
  },
  {
    path: RoutePath.CLASS_STORE,
    tabName: "store",
    labelKey: "layout.store",
    activeOnPaths: [`${RoutePath.CLASS_STORE}/*`],
  },
  {
    path: RoutePath.CLASS_SETUP,
    tabName: "setup",
    labelKey: "layout.setup",
    activeOnPaths: [`${RoutePath.CLASS_SETUP}/*`],
  },
  {
    path: RoutePath.CLASS_COTEACHERS,
    tabName: "coteachers",
    labelKey: "layout.coteachers",
    activeOnPaths: [RoutePath.CLASS_COTEACHERS],
  },
  {
    path: RoutePath.CLASS_STUDENT_HELPERS,
    tabName: "Student Helpers",
    labelKey: "layout.studentHelpers",
    activeOnPaths: [RoutePath.CLASS_STUDENT_HELPERS],
  },
]

export const studentClassRoutes = [
  {
    path: RoutePath.CLASS,
    tabName: "jobs",
    labelKey: "layout.jobs",
    activeOnPaths: [RoutePath.CLASS],
  },
  {
    path: RoutePath.CLASS_STORE,
    tabName: "store",
    labelKey: "layout.store",
    activeOnPaths: [RoutePath.CLASS_STORE],
  },
]

export const basicRoutes = [
  {
    path: RoutePath.HOMEPAGE,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.ALL_TRANSACTIONS,
    name: "layout.allTransactions",
    icon: "document",
  },
  {
    path: RoutePath.STUDENT,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.ACCOUNTS,
    name: "layout.accounts",
    icon: "document",
  },
  {
    path: RoutePath.PAYMENT_SUCCESSFUL,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.SETTINGS,
    name: "layout.dashboard",
    icon: "home",
  },
  {
    path: RoutePath.PAGE404,
    name: "layout.pageNotFound",
    icon: "star",
  },
]
