import { FC } from "react"

import { ReactComponent as IconQuestionMark } from "@common_assets/svg/question.svg"
import {
  Box,
  Skeleton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutationPostClassesStoreClerk } from "api/reactQuery/mutations/classesStore"
import { useQueryClassesStoreClerk } from "api/reactQuery/queries/classesStore"
import ErrorText from "components/common/error/errorText"
import IconWrapper from "components/common/icon/iconWrapper"
import ContentTabs from "components/common/navigation/contentTabs"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { Outlet } from "react-router"
import { getErrorMessage } from "utils/api"
import { transformRoutes } from "utils/tab"

import { storeClerkRoutes } from "./StudentStoreLayout.config"

const StudentStoreLayout: FC = () => {
  const classId = useClassId()
  const tabsStoreClerk = transformRoutes(storeClerkRoutes, ":classId", classId)
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()

  const { data, isLoading, isError } = useQueryClassesStoreClerk({
    classId: Number(classId),
  })

  const { mutate, isLoading: isPostLoading } = useMutationPostClassesStoreClerk(
    {
      options: {
        onMutate: async (newState) => {
          await queryClient.cancelQueries([
            "classesStoreClerk",
            Number(classId),
          ])
          const prevValue = queryClient.getQueryData([
            "classesStoreClerk",
            Number(classId),
          ])
          queryClient.setQueryData(
            ["classesStoreClerk", Number(classId)],
            newState
          )
          return prevValue
        },
        onSuccess: () => {
          queryClient.invalidateQueries(["studentStoreItems"])
          showSnackbar({
            title: !data?.data.open
              ? t("store.classroomHasBeenOpened")
              : t("store.classroomHasBeenClosed"),
          })
        },
        onError: (error, _, prevValue) => {
          queryClient.setQueryData(
            ["classesStoreClerk", Number(classId)],
            prevValue
          )
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
        onSettled: () => queryClient.invalidateQueries("classesStoreClerk"),
      },
    }
  )

  return (
    <Box>
      <Stack
        mb="24px"
        justifyContent="space-between"
        alignItems={{ tablet: "center" }}
        direction={{ mobile: "column", tablet: "row" }}
        columnGap="12px"
        rowGap="16px"
      >
        <Typography color="mockup.neutral10" fontSize="28px" lineHeight="36px">
          {t("store.store")}
        </Typography>
        {isLoading && <Skeleton width="290px" height="26px" />}
        {!isLoading && isError && <ErrorText />}
        {!isLoading && data?.data && (
          <Stack
            direction="row"
            alignItems="center"
            gap="12px"
            justifyContent={{ mobile: "space-between", tablet: "normal" }}
          >
            <Stack
              direction={{ mobile: "row-reverse", tablet: "row" }}
              alignItems="center"
              gap="12px"
            >
              <Typography
                variant="subtitle1"
                color="primary.main"
                letterSpacing="0.16px"
                lineHeight="16px"
                fontSize="16px"
                textTransform="uppercase"
              >
                {data?.data.open
                  ? t("store.classroomStoreOpen")
                  : t("store.classroomStoreClosed")}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="12px">
              <Switch
                disabled={isPostLoading}
                checked={data?.data.open}
                onChange={(e) => {
                  mutate({
                    classId: Number(classId),
                    data: { open: e.target.checked },
                  })
                }}
              />
              <IconWrapper>
                <Tooltip
                  title={t("store.whenTheClassroomStoreIsOpen")}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: "mockup.grey70",
                      },
                    },
                  }}
                >
                  <IconQuestionMark />
                </Tooltip>
              </IconWrapper>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Box height="43px" width="100%" position="relative">
        <Box position="absolute" width="100%" top="0" left="0">
          <ContentTabs tabs={tabsStoreClerk} />
        </Box>
      </Box>
      <Outlet />
    </Box>
  )
}

export default StudentStoreLayout
