import axiosInstance, { Endpoint } from "api"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostStoreTransactionArgs,
  IPostStoreTransactionInput,
  IPostStoreTransactionsArgs,
  IPostStoreTransactionsInput,
} from "./storeTransactions.types"

export const useMutationPostStoreTransactionAccept = (
  hookArgs?: IPostStoreTransactionArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionInput>(
    async ({ id }) =>
      await axiosInstance.post(
        Endpoint.STORE_TRANSACTIONS_ID_ACCEPT.replace("ID", `${id}`)
      ),
    hookArgs?.options
  )

export const useMutationPostStoreTransactionReject = (
  hookArgs?: IPostStoreTransactionArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionInput>(
    async ({ id }) =>
      await axiosInstance.post(
        Endpoint.STORE_TRANSACTIONS_ID_REJECT.replace("ID", `${id}`)
      ),
    hookArgs?.options
  )

export const useMutationPostStoreTransactionsAccept = (
  hookArgs?: IPostStoreTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionsInput>(
    async ({ transaction_ids }) =>
      await axiosInstance.post(Endpoint.STORE_TRANSACTIONS_ACCEPT, {
        transaction_ids,
      }),
    hookArgs?.options
  )

export const useMutationPostStoreTransactionsReject = (
  hookArgs?: IPostStoreTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionsInput>(
    async ({ transaction_ids }) =>
      await axiosInstance.post(Endpoint.STORE_TRANSACTIONS_REJECT, {
        transaction_ids,
      }),
    hookArgs?.options
  )

export const useMutationPostStoreClerkTransactionAccept = (
  hookArgs?: IPostStoreTransactionArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionInput>(
    async ({ id }) =>
      await axiosInstance.post(
        Endpoint.STORE_TRANSACTIONS_ID_ACCEPT.replace("ID", `${id}`)
      ),
    hookArgs?.options
  )

export const useMutationPostStoreClerkTransactionReject = (
  hookArgs?: IPostStoreTransactionArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionInput>(
    async ({ id, storeId }) =>
      await axiosInstance.post(
        Endpoint.ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_STOREID_REJECT.replace(
          "ID",
          `${id}`
        ).replace("STOREID", `${storeId}`)
      ),
    hookArgs?.options
  )

export const useMutationPostStoreClerkTransactionsAccept = (
  hookArgs?: IPostStoreTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionsInput>(
    async ({ transaction_ids }) =>
      await axiosInstance.post(
        Endpoint.ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_ACCEPT.replace(
          "ID",
          `${hookArgs?.id}`
        ),
        {
          transaction_ids,
        }
      ),
    hookArgs?.options
  )

export const useMutationPostStoreClerkTransactionsReject = (
  hookArgs?: IPostStoreTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStoreTransactionsInput>(
    async ({ transaction_ids }) =>
      await axiosInstance.post(
        Endpoint.ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_REJECT.replace(
          "ID",
          `${hookArgs?.id}`
        ),
        {
          transaction_ids,
        }
      ),
    hookArgs?.options
  )
