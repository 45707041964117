import React, { Dispatch, FC, useEffect, useState } from "react"

import { ReactComponent as IconOffer } from "@common_assets/svg/offer-outlined.svg"
import { ReactComponent as IconQuestionMark } from "@common_assets/svg/question.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { IPostEditClass } from "api/reactQuery/mutations/classes.type"
import Dialog from "components/common/dialog/dialog"
import DynamicIcon from "components/common/icon/dynamicIcon"
import { icons } from "components/common/icon/dynamicIcon/DynamicIcon.config"
import { HREFLINK } from "components/common/link/hreflink"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { Action } from "layout/asideNav/navigation/Navigation.utility"
import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { UserSchoolPlan } from "ts/enums/User"
import { IClassShort } from "ts/interfaces/Class"
import { IUserMe } from "ts/interfaces/User"
import { payCheck_interval } from "utils/paycheckInterval"

import {
  clearError,
  defaultValues,
  schemaStructure,
  setterValue,
} from "./AddNewClass.config"
import {
  ErrorStyle,
  TabsStyle,
  TabStyle,
  ToggleButtonStyled,
} from "./AddNewClass.style"
import { IResponseNewClass } from "./AddNewClass.types"
import Advance from "./advance"
import { grades } from "./utily"

interface IProps {
  state: IResponseNewClass
  setState: Dispatch<React.SetStateAction<IResponseNewClass>>
  id?: string
  createNewClass?: (data: IResponseNewClass) => void
  editClass?: (data: IPostEditClass) => void
  dispatchState?: Dispatch<Action>
  getDataId?: IResponseNewClass
  allClasses?: IClassShort[]
  isEditClass?: boolean
  userInfo: IUserMe | null
}

const AddNewClass: FC<IProps> = ({
  state,
  editClass,
  setState,
  id,
  createNewClass,
  dispatchState,
  getDataId,
  allClasses,
  isEditClass,
  userInfo,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isApplySettings, setIsApplySettings] = useState(false)
  const [copyClassId, setCopyClassId] = useState<number | undefined>(undefined)
  const [tab, setTab] = useState("general")

  const handleChange = (event: React.SyntheticEvent, newVal: string) => {
    setTab(newVal)
  }

  const methods = useForm<IResponseNewClass>({
    resolver: yupResolver(schemaStructure),
    defaultValues: defaultValues,
  })

  const {
    isOpen: isDialogOpenUpgradePlan,
    handleOpen: handleOpenDialogUpgradePlan,
    handleClose: handleCloseDialogUpgradePlan,
  } = useDialog()

  const {
    isOpen: isDialogOpenSavingsOff,
    handleOpen: handleOpenDialogSavingsOff,
    handleClose: handleCloseDialogSavingsOff,
  } = useDialog()

  const { payIt, isLoadingPayments } = useCustomPayment()

  useEffect(() => {
    clearError(state, methods)
  }, [state])

  useEffect(() => {
    setterValue(state, methods, isApplySettings)
  }, [state, isApplySettings])

  const handleClick = (data: IResponseNewClass) => {
    if (id === "form") {
      if (createNewClass) {
        createNewClass({
          ...data,
          expense_interval:
            data?.expense_interval === "" ? null : data?.expense_interval,
          paycheck_interval:
            data?.paycheck_interval === "" ? null : data?.paycheck_interval,
          interest_granting_interval:
            data?.interest_granting_interval === ""
              ? null
              : data?.interest_granting_interval,
          saving_interest_rate: Number(data?.saving_interest_rate),
          copy_settings: isApplySettings,
          copy_from_id: copyClassId,
        })
      }
    } else {
      const { name, ...rest } = state
      editClass &&
        editClass({
          classId: Number(state.id),
          data: {
            ...rest,
            ...(getDataId?.name === name ? {} : { name }),
            description: "",
            grade: state.grade,
            icon: state.icon,
            paycheck_interval: state.paycheck_interval,
            paycheck_automatic: state.paycheck_automatic,
            expense_automatic: state.expense_automatic,
            expense_interval: state.expense_interval,
            savings_account_option: state.savings_account_option,
            interest_option: state.interest_option,
            interest_granting_interval: state.interest_granting_interval,
            saving_interest_rate: Number(state.saving_interest_rate),
            round_interest_to_whole_number:
              state.round_interest_to_whole_number,
          },
        })
    }
  }

  return (
    <form id={id} onSubmit={methods.handleSubmit(handleClick)}>
      <Stack>
        <Grid item>
          <Box>
            <TabsStyle onChange={handleChange} value={tab} variant="scrollable">
              <TabStyle value="general" label={t("addNewClass.general")} />
              {isEditClass && (
                <TabStyle value="advanced" label={t("addNewClass.advanced")} />
              )}
            </TabsStyle>
          </Box>
        </Grid>
        {tab === "advanced" && (
          <Advance classId={state.id} dispatchState={dispatchState} />
        )}
        {tab === "general" && (
          <>
            <Grid container direction="column">
              <Grid item mt={3} mb={3}>
                <TextField
                  inputProps={{
                    maxLength: 100,
                  }}
                  error={!!methods.formState.errors.name?.message}
                  {...methods.register("name", {
                    onChange: (e) =>
                      setState((prev) => ({ ...prev, name: e.target.value })),
                  })}
                  label={t("addNewClass.className")}
                  value={state.name}
                />
                {methods.formState.errors.name?.message && (
                  <ErrorStyle>
                    {t("validations.enterMinChars_one", {
                      count: 3,
                    })}
                  </ErrorStyle>
                )}
              </Grid>
              <Grid item>
                <TextField
                  error={!!methods.formState.errors.grade?.message}
                  {...methods.register("grade", {
                    onChange: (e) =>
                      setState((prev) => ({ ...prev, grade: e.target.value })),
                  })}
                  select
                  label={t("addNewClass.grade")}
                  value={state.grade}
                >
                  {grades.map((grade, index) => (
                    <MenuItem key={index} value={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </TextField>
                {methods.formState.errors.grade?.message && (
                  <ErrorStyle>{t("addNewClass.selectOneOption")}</ErrorStyle>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%", mt: 6, mb: 3 }} />
            <Box>
              <Typography variant="body3" color="mockup.neutral40">
                {t("addNewClass.selectClassIcon")}
              </Typography>
              <ToggleButtonGroup
                sx={{
                  mt: 4,
                  display: "flex",
                  flexWrap: "wrap",
                }}
                size="small"
                value={state.icon}
                exclusive
                {...methods.register("icon")}
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newIcon: string | null
                ) => setState((prev) => ({ ...prev, icon: newIcon }))}
              >
                {Object.keys(icons).map((icon, index) => (
                  <ToggleButtonStyled key={index} value={icon}>
                    <DynamicIcon name={icon} variant="outlined" />
                  </ToggleButtonStyled>
                ))}
              </ToggleButtonGroup>
              {methods.formState.errors.icon?.message && (
                <ErrorStyle>{t("addNewClass.selectOneIcon")}</ErrorStyle>
              )}
            </Box>
            <Divider sx={{ width: "100%", mb: 3, mt: 4 }} />
            {id === "form" && allClasses?.length !== 0 && (
              <Grid container sx={{ pl: 3 }} direction="column">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isApplySettings}
                        onChange={() => {
                          setIsApplySettings(!isApplySettings)
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          lineHeight: "24px",
                          color: theme.palette.mockup.neutral0,
                        }}
                      >
                        {t("addNewClass.applySettings")}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sx={{ pl: 3 }}>
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    sx={{
                      mt: 1,
                      lineHeight: "24px",
                      color: theme.palette.mockup.grey70,
                    }}
                  >
                    {t(
                      "addNewClass.allBonusesStoreItemsJobsExpensesAndFinesFromTheSelectedClassWillBeCopied"
                    )}
                  </Typography>
                  <TextField
                    error={!!methods.formState.errors.copy_from_id?.message}
                    {...methods.register("copy_from_id", {
                      onChange: (e) => setCopyClassId(Number(e.target.value)),
                    })}
                    sx={{ mt: 3, mb: 1 }}
                    select
                    disabled={!isApplySettings}
                    label={t("addNewClass.selectClass")}
                    value={copyClassId || ""}
                  >
                    {allClasses?.map((singleClass) => (
                      <MenuItem key={singleClass.id} value={singleClass.id}>
                        {singleClass.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {methods.formState.errors.copy_from_id?.message && (
                    <ErrorStyle>{t("addNewClass.selectOneOption")}</ErrorStyle>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container sx={{ pl: 3, pt: 1 }} direction="column">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.savings_account_option}
                      onChange={(e) => {
                        if (isEditClass && state.savings_account_option) {
                          handleOpenDialogSavingsOff()
                          return
                        }
                        setState((prev) => ({
                          ...prev,
                          savings_account_option: e.target.checked,
                        }))
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        lineHeight: "24px",
                        color: theme.palette.mockup.neutral0,
                      }}
                    >
                      {t("addNewClass.savingsAccount")}
                    </Typography>
                  }
                />
                <Dialog
                  open={isDialogOpenSavingsOff}
                  onClose={handleCloseDialogSavingsOff}
                  onActionButtonClick={() => {
                    setState((prev) => ({
                      ...prev,
                      savings_account_option: false,
                    }))
                    handleCloseDialogSavingsOff()
                  }}
                >
                  <Typography color="mockup.neutral10">
                    {t(
                      "addNewClass.pleaseEnsureStudentsHaveTransitionedAllMoneyFromTheirSavingsToTheirCheckingAccountBeforeTurningOffSavingsAccounts"
                    )}
                  </Typography>
                </Dialog>
              </Grid>
              <Grid item sx={{ pl: 3 }}>
                <Typography
                  variant="body1"
                  fontWeight="500"
                  sx={{
                    mt: 1,
                    mb: 2,
                    lineHeight: "24px",
                    color: theme.palette.mockup.grey70,
                  }}
                >
                  {t(
                    "addNewClass.allowYourStudentsToBudgetAndSeeThePowerOfCompoundInterest"
                  )}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.interest_option}
                      disabled={!state.savings_account_option}
                      onChange={(e) => {
                        userInfo?.user.school_plan === UserSchoolPlan.FREE
                          ? handleOpenDialogUpgradePlan()
                          : setState((prev) => ({
                              ...prev,
                              interest_option: e.target.checked,
                            }))
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontWeight="500"
                      sx={{
                        mt: 1,
                        lineHeight: "24px",
                        color: theme.palette.mockup.grey70,
                      }}
                    >
                      {t(
                        "addNewClass.allowStudentsToEarnInterestOnTheirSavings"
                      )}
                    </Typography>
                  }
                />
                <Dialog
                  open={isDialogOpenUpgradePlan}
                  onClose={handleCloseDialogUpgradePlan}
                  titleText={t("addNewClass.interestOption")}
                  actionAcceptText={t("coteachers.upgrade")}
                  onActionButtonClick={() => {
                    payIt(null)
                  }}
                  isLoading={isLoadingPayments}
                >
                  <Typography>
                    <Trans
                      i18nKey={t(
                        "addNewClass.descriptionUpgradeTeacherInterest"
                      )}
                      components={{
                        Link: HREFLINK,
                      }}
                    />
                  </Typography>
                </Dialog>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconOffer />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    !!methods.formState.errors.saving_interest_rate?.message
                  }
                  sx={{ mt: 3, mb: 1 }}
                  disabled={
                    !state.savings_account_option || !state.interest_option
                  }
                  label={t("addNewClass.interestRate")}
                  value={state.saving_interest_rate}
                  onChange={(e) =>
                    setState({
                      ...state,
                      saving_interest_rate: e.target.value,
                    })
                  }
                />
                <ErrorStyle>
                  {methods.formState.errors.saving_interest_rate?.message}
                </ErrorStyle>
                <TextField
                  error={
                    !!methods.formState.errors.interest_granting_interval
                      ?.message
                  }
                  {...methods.register("interest_granting_interval", {
                    onChange: (e) =>
                      setState((prev) => ({
                        ...prev,
                        interest_granting_interval: e.target.value,
                      })),
                  })}
                  sx={{ mt: 3, mb: 1 }}
                  select
                  disabled={
                    !state.savings_account_option || !state.interest_option
                  }
                  label={t("addNewClass.interestWillBeSent")}
                  value={state.interest_granting_interval}
                >
                  {payCheck_interval.map((pay) => (
                    <MenuItem key={pay.id} value={pay.value}>
                      {pay.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Stack direction="row" alignItems="end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.round_interest_to_whole_number}
                        disabled={!state.savings_account_option}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            round_interest_to_whole_number: e.target.checked,
                          }))
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        sx={{
                          mt: 1,
                          lineHeight: "24px",
                          color: theme.palette.mockup.grey70,
                        }}
                      >
                        {t("addNewClass.roundInterestToNearestWholeNumber")}
                      </Typography>
                    }
                  />
                  <Tooltip title={t("addNewClass.exWillRoundTo")}>
                    <IconQuestionMark />
                  </Tooltip>
                </Stack>
                {methods.formState.errors.interest_granting_interval
                  ?.message && (
                  <ErrorStyle>{t("addNewClass.selectOneOption")}</ErrorStyle>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </form>
  )
}

export default AddNewClass
