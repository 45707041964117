import React, { useState, useEffect } from "react"

import { ReactComponent as IconExport } from "@common_assets/svg/export.svg"
import { ReactComponent as IconFilters } from "@common_assets/svg/filters.svg"
import { ReactComponent as Trash } from "@common_assets/svg/trash.svg"
import {
  Box,
  CircularProgress,
  Fab,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useMutationTransactions } from "api/reactQuery/mutations/transactions"
import { useQueryExportTeacherTransactions } from "api/reactQuery/queries/export"
import { useQueryTeacherTransactions } from "api/reactQuery/queries/transaction"
import { ITeacherTransactions } from "api/reactQuery/queries/transaction.types"
import Dialog from "components/common/dialog/dialog"
import ErrorText from "components/common/error/errorText"
import IconWrapper from "components/common/icon/iconWrapper"
import BasicTable from "components/common/table/basicTable"
import { PrimaryTile } from "components/common/tile"
import SearchbarForm from "components/form/searchbarForm"
import { format, isValid } from "date-fns"
import { useBlobData } from "hooks/blobData"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useDialog } from "hooks/dialog"
import { useCustomPayment } from "hooks/payment"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { selectUser } from "store/auth/auth.selectors"
import { ExportIconWrapper } from "styles/common/box"
import { getErrorMessageFromTab } from "utils/api"
import { schoolPlanToExportData } from "utils/export"

import DialogUpgradeAccount from "../../common/dialogUpgradeAccount/DialogUpgradeAccount"
import BodyAllTransactions from "./bodyAllTransactions"
import FilterOptions from "./filterOptions"
import HeadAllTransactions from "./headAllTransactions"
import PopoverContent from "./popoverContent"
import { typeTransactions } from "./popoverContent/PopoverContent.utility"

export type TypeTransactions = typeof typeTransactions[number]

export interface ClassnameProps {
  name: string
  id: number
}

const AllTransactionsTeacher = () => {
  const theme = useTheme()
  const [searchTransaction, setSearchTransaction] = useState("")
  const [isExportData, setIsExportData] = useState(false)
  const [isApply, setIsApply] = useState(false)
  const [searchStudent, setSearchStudent] = useState("")
  const [singleId, setSingleId] = useState<number[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [data, setData] = useState<ITeacherTransactions[]>([])
  const [isNextPage, setIsNextPage] = useState(true)
  const [page, setPage] = useState(0)
  const [typeTransactionsArray, setTypeTransactionsArray] = useState<
    TypeTransactions[]
  >([])
  const [classesSelectedArray, setClassesSelectedArray] = useState<number[]>([])
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(null)
  const [classesNameArray, setClassesNameArray] = useState<ClassnameProps[]>([])
  const [selectedDateTo, setSelectedDateTo] = useState<Date | null>(null)
  const [optionFilters, setOptionFilters] = useState<
    (ClassnameProps | string)[]
  >([])
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const isMobile = !useIsBreakpointUp("tablet")
  const userInfo = useAppSelector(selectUser)

  const { handleClick, handleClose, popoverAnchorEl } =
    usePopover<HTMLDivElement>()

  const { payIt, isLoadingPayments } = useCustomPayment()

  const { ref, inView } = useInView()

  const {
    handleClose: handleCloseDialog,
    handleOpen: handleOpenDialog,
    isOpen: isOpenDialog,
  } = useDialog()
  const {
    handleClose: handleCloseTrashDialog,
    handleOpen: handleOpenTrashDialog,
    isOpen: isOpenTrashDialog,
  } = useDialog()

  const { refetch, isRefetching, isLoading, isError } =
    useQueryTeacherTransactions({
      limit: 20,
      page,
      title_filter: !!searchTransaction ? searchTransaction : undefined,
      student_filter: !!searchStudent ? searchStudent : undefined,
      type: !!typeTransactionsArray.length ? typeTransactionsArray : undefined,
      date_from:
        selectedDateFrom !== null && isValid(selectedDateFrom)
          ? format(selectedDateFrom, "yyyy-MM-dd")
          : undefined,
      date_to:
        selectedDateTo !== null && isValid(selectedDateTo)
          ? format(selectedDateTo, "yyyy-MM-dd")
          : undefined,
      classes: classesSelectedArray,
      options: {
        enabled: inView && isNextPage,
        cacheTime: 0,
        onSuccess: (data) => {
          if (data.data.length === 20) {
            setIsNextPage(true)
            setPage((prev) => prev + 20)
          }

          if (data.data.length < 20) {
            setIsNextPage(false)
            setPage(0)
          }

          setData((prev) => [...prev, ...data?.data])
          handleClose()
        },
        onError: (err) => {
          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  const { isFetching, refetch: isRefetchExportData } =
    useQueryExportTeacherTransactions({
      title_filter: !!searchTransaction ? searchTransaction : undefined,
      student_filter: !!searchStudent ? searchStudent : undefined,
      type_in: !!typeTransactionsArray.length
        ? typeTransactionsArray
        : undefined,
      date_from:
        selectedDateFrom !== null && isValid(selectedDateFrom)
          ? format(selectedDateFrom, "yyyy-MM-dd")
          : undefined,
      date_to:
        selectedDateTo !== null && isValid(selectedDateTo)
          ? format(selectedDateTo, "yyyy-MM-dd")
          : undefined,
      classes: classesSelectedArray,
      options: {
        enabled: isExportData,
        onSuccess: (response) => {
          setIsExportData(false)
          useBlobData({
            response: response.data,
            classname: "Transactions",
            exportData: "History",
          })
          showSnackbar({
            title: t("allTransactions.dataHasBeenSuccessfullyExported"),
          })
        },
        onError: async (err) => {
          if (err.response?.data instanceof Blob) {
            const objectURL = URL.createObjectURL(err.response?.data)
            try {
              const response = await fetch(objectURL)
              const data = await response.json()
              showSnackbar({
                title: data.detail,
                variant: "error",
              })
            } catch (err) {}
            return
          }

          showSnackbar({
            title: getErrorMessageFromTab(err),
            variant: "error",
          })
        },
      },
    })

  const refreshTableAfterDeletedTransaction = (transaction: number) => {
    const copyData = [...data]
    const result = copyData.filter((element) => element.id !== transaction)
    setData(result)
  }

  const refreshTableAfterDeletedSelectedTransactions = (
    transactions: number[]
  ) => {
    const copyData = [...data]
    const result = copyData.filter(
      (number) => !transactions.includes(number.id)
    )
    setData(result)
  }

  const { mutate } = useMutationTransactions({
    options: {
      onSuccess: () => {
        showSnackbar({
          title: t("allTransactions.transactionHasBeenDeletedSuccessful"),
        })

        if (!!singleId.length) {
          refreshTableAfterDeletedTransaction(singleId[0])
          setPage((prev) => prev - 1)
          setSingleId([])
          handleCloseDialog()
        } else {
          refreshTableAfterDeletedSelectedTransactions(selectedIds)
          setPage((prev) => prev - selectedIds.length)
          setSelectedIds([])
          handleCloseTrashDialog()
        }
      },
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    if (inView && isNextPage) {
      refetch()
    }
  }, [inView])

  useEffect(() => {
    if (
      searchTransaction.length >= 2 ||
      !searchTransaction.length ||
      searchStudent.length >= 2 ||
      !searchStudent.length
    ) {
      setData([])
      setPage(0)
    }
  }, [searchTransaction, searchStudent])

  useEffect(() => {
    if (
      (searchTransaction.length >= 2 || !searchTransaction.length) &&
      page === 0 &&
      (inView || !isNextPage)
    ) {
      refetch()
    }
  }, [searchTransaction])

  useEffect(() => {
    if (
      (searchStudent.length >= 2 || !searchStudent.length) &&
      page === 0 &&
      (inView || !isNextPage)
    ) {
      refetch()
    }
  }, [searchStudent])

  const handleCheckboxTransactions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedName = event.target.name
    const isChecked = event.target.checked

    if (isChecked) {
      setTypeTransactionsArray((prev) => [...prev, checkedName.toLowerCase()])
    } else {
      setTypeTransactionsArray((prev) =>
        prev.filter((type) => type !== checkedName.toLowerCase())
      )
    }
  }

  const handleCheckboxClasses = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedId = event.target.value
    const isChecked = event.target.checked
    const className = event.target.name

    if (isChecked) {
      setClassesSelectedArray((prev) => [...prev, Number(checkedId)])
      setClassesNameArray((prev) => [
        ...prev,
        { name: className, id: Number(checkedId) },
      ])
    } else {
      setClassesSelectedArray((prev) =>
        prev.filter((id) => id !== Number(checkedId))
      )
      setClassesNameArray((prev) =>
        prev.filter(({ name }) => name !== className)
      )
    }
  }

  const handleDateFrom = (date: Date | null) => {
    setSelectedDateFrom(date)
  }

  const handleDateTo = (date: Date | null) => {
    setSelectedDateTo(date)
  }

  const resetAllOptions = () => {
    setTypeTransactionsArray([])
    setClassesSelectedArray([])
    setClassesNameArray([])
    setSelectedDateFrom(null)
    setSelectedDateTo(null)
  }

  const handleChecked = (id: number) => {
    const newSelected = selectedIds.filter((item) => item !== id)
    if (newSelected.length === selectedIds.length) {
      setSelectedIds((prev) => [...prev, id])
    } else {
      setSelectedIds(newSelected)
    }
  }

  const toggleAll = () => {
    if (selectedIds.length === data.length) {
      setSelectedIds([])
    } else {
      const ids = data.map(({ id }) => id)
      setSelectedIds(ids)
    }
  }

  useEffect(() => {
    const initialFilters = [...typeTransactionsArray, ...classesNameArray]

    const addDateFilterIfSelected = (date: Date | null) => {
      if (date) {
        initialFilters.push(format(date, "dd-MM-yyyy"))
      }
    }

    addDateFilterIfSelected(selectedDateFrom)
    addDateFilterIfSelected(selectedDateTo)

    setOptionFilters(initialFilters)
  }, [isApply])

  const {
    isOpen: isOpenExport,
    handleOpen: handleOpenExport,
    handleClose: handleCloseExport,
  } = useDialog()

  return (
    <PrimaryTile sx={{ p: 2 }}>
      <Box
        sx={{ display: "flex", columnGap: "10px", mb: 3 }}
        alignItems="center"
      >
        <Stack
          width="100%"
          columnGap={1}
          rowGap={2}
          direction={isMobile ? "column" : "row"}
        >
          <SearchbarForm
            placeholder={`${t(
              "allTransactions.searchForStudentOrTransactionTitle",
              { things: "transaction title" }
            )}...`}
            onSubmit={(data) => setSearchTransaction(data.search)}
          />
          <SearchbarForm
            placeholder={`${t(
              "allTransactions.searchForStudentOrTransactionTitle",
              { things: "student" }
            )}...`}
            onSubmit={(data) => setSearchStudent(data.search)}
          />
        </Stack>
        <Stack rowGap={2} direction={isMobile ? "column" : "row"} columnGap={1}>
          <Tooltip
            title={t("allTransactions.options")}
            arrow={true}
            placement="top"
          >
            <ExportIconWrapper
              onClick={handleClick}
              sx={{
                minWidth: "48px",
                height: "48px",
                m: "auto",
              }}
            >
              <IconFilters
                style={{
                  color: theme.palette.primary.main,
                  margin: "auto",
                }}
              />
            </ExportIconWrapper>
          </Tooltip>
          <DialogUpgradeAccount
            open={isOpenExport}
            onClose={() => {
              handleCloseExport()
              mixpanel.track("reports_export", {
                distinct_id: `${userInfo?.user.id}`,
                method: "teacher_all_transactions",
                action: "cancel",
              })
            }}
            onActionButtonClick={() => {
              payIt(null)
              mixpanel.track("reports_export", {
                distinct_id: `${userInfo?.user.id}`,
                method: "teacher_all_transactions",
                action: "submit",
              })
            }}
            isLoading={isLoadingPayments}
            isExport
          />
          <Tooltip
            title={t("allTransactions.export")}
            arrow={true}
            placement="top"
          >
            <ExportIconWrapper
              sx={{
                minWidth: "48px",
                height: "48px",
                m: "auto",
              }}
              onClick={() => {
                if (isFetching) return
                schoolPlanToExportData({
                  user: userInfo,
                  upgrade: handleOpenExport,
                  exportData: isRefetchExportData,
                })
              }}
            >
              <span style={{ margin: "auto" }}>
                {isFetching ? (
                  <CircularProgress />
                ) : (
                  <IconExport
                    style={{
                      color: theme.palette.primary.main,
                      margin: "auto",
                    }}
                  />
                )}
              </span>
            </ExportIconWrapper>
          </Tooltip>
        </Stack>
        <Popover
          open={!!popoverAnchorEl}
          anchorEl={popoverAnchorEl}
          marginThreshold={60}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <PopoverContent
            {...{
              typeTransactionsArray,
              selectedDateFrom,
              selectedDateTo,
              handleCheckboxTransactions,
              handleCheckboxClasses,
              handleDateFrom,
              handleDateTo,
              classesSelectedArray,
              isLoading,
            }}
            onApply={(errorFrom, errorTo) => {
              if (errorFrom || errorTo) return

              setData([])
              setPage(0)
              setIsApply(!isApply)
              setSelectedIds([])
              setTimeout(() => refetch(), 0)
            }}
            onReset={resetAllOptions}
          />
        </Popover>
      </Box>
      <FilterOptions
        filterName={optionFilters}
        onClick={(val) => {
          setOptionFilters((prev) =>
            prev.filter((el) => {
              if (
                typeof val === "object" &&
                "name" in val &&
                typeof el === "object"
              ) {
                return el.name !== val.name
              }
              return el !== val
            })
          )

          const findedName = classesNameArray.find((el) => {
            if (typeof val === "object" && "name" in val) {
              return val.name === el.name
            }
          })

          setClassesSelectedArray((prev) =>
            prev.filter((id) => id !== findedName?.id)
          )

          setClassesNameArray((prev) =>
            prev.filter((el) => {
              if (typeof val === "object" && "name" in val) {
                return el.name !== val.name
              }
              return el
            })
          )

          setTypeTransactionsArray((prev) => prev.filter((el) => el !== val))
          if (selectedDateFrom !== null) {
            const formatData = format(selectedDateFrom, "dd-MM-yyyy")
            formatData === val && setSelectedDateFrom(null)
          }

          if (selectedDateTo !== null) {
            const formatData = format(selectedDateTo, "dd-MM-yyyy")
            formatData === val && setSelectedDateTo(null)
          }

          setData([])
          setPage(0)
          setTimeout(() => refetch(), 1000)
        }}
        onClickReset={() => {
          resetAllOptions()
          setData([])
          setPage(0)
          setOptionFilters([])
          setTimeout(() => {
            refetch()
          }, 0)
        }}
      />
      <BasicTable
        topSlot={
          !!selectedIds.length && (
            <Stack
              p="8px 16px"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ backgroundColor: theme.palette.mockup.primary95 }}
            >
              <Typography variant="subtitle1" color="mockup.neutral10">
                {selectedIds.length}{" "}
                {t("allTransactions.itemsSelected", {
                  s: selectedIds.length >= 2 ? "s" : "",
                })}
              </Typography>
              <Tooltip title={t("allTransactions.removeSelected")} arrow={true}>
                <Fab sx={{ flexShrink: 0 }} onClick={handleOpenTrashDialog}>
                  <IconWrapper color="primary.main">
                    <Trash />
                  </IconWrapper>
                </Fab>
              </Tooltip>
            </Stack>
          )
        }
        head={
          <HeadAllTransactions
            onChange={toggleAll}
            isChecked={data.length === selectedIds.length && !!data.length}
            isIndeterminate={
              data.length !== selectedIds.length && !!selectedIds.length
            }
          />
        }
        body={
          <TransitionGroup component={null}>
            {data.map((transaction) => (
              <CSSTransition
                key={transaction.id}
                timeout={500}
                classNames="animation-fade"
              >
                <BodyAllTransactions
                  key={transaction.id}
                  data={transaction}
                  onClick={() => {
                    handleOpenDialog()
                    setSingleId([transaction.id])
                  }}
                  getChecked={!!selectedIds.find((el) => el === transaction.id)}
                  onChange={() => handleChecked(transaction.id)}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        }
        bottomSlot={
          <Box textAlign="center" py={2} ref={ref}>
            {isError && <ErrorText />}
            {(isLoading || isRefetching) && (
              <span>
                <CircularProgress />
              </span>
            )}
            {!isRefetching && !isLoading && !data.length && !isError && (
              <Typography variant="body1" color="mockup.neutral10">
                {t("allTransactions.noResults")}
              </Typography>
            )}
          </Box>
        }
      />
      <Dialog
        open={isOpenDialog}
        onClose={() => {
          handleCloseDialog()
          setSingleId([])
        }}
        titleText={t("allTransactions.areYouSureYouWantToDeleteTransaction")}
        onActionButtonClick={() => mutate({ transaction_ids: singleId })}
      />
      <Dialog
        open={isOpenTrashDialog}
        onClose={handleCloseTrashDialog}
        titleText={t(
          "allTransactions.areYouSureYouWantToDeleteSelectedTransactions"
        )}
        onActionButtonClick={() => mutate({ transaction_ids: selectedIds })}
      />
    </PrimaryTile>
  )
}

export default AllTransactionsTeacher
