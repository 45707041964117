import { Box, Tab, Tabs } from "@mui/material"
import { useQueryStudentClassesById } from "api/reactQuery/queries/studentClasses"
import { useClassId, useGetCurrentTabName } from "hooks/navigation"
import { useAppSelector } from "hooks/store"
import { useUserRole } from "hooks/user"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { UserRole, UserSchoolPlan } from "ts/enums/User"

import { classRoutes, studentClassRoutes } from "../Header.config"

const NavigationTabs = () => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(selectUser)
  const userRole = useUserRole()
  const routes =
    userRole === UserRole.TEACHER ? classRoutes : studentClassRoutes
  const location = useLocation()

  const additionalTabs = ["banker", "storeClerk", "hrRepresentative"]
  const currentAdditionalTab = additionalTabs.find((tab) =>
    location.pathname.includes(tab)
  )

  const tabName = useGetCurrentTabName(routes)

  const classId = useClassId()

  const { data } = useQueryStudentClassesById({
    id: Number(classId),
  })

  return (
    <Box>
      <Tabs
        value={
          additionalTabs.some((tab) => location.pathname.includes(tab))
            ? currentAdditionalTab
            : tabName
        }
        variant="scrollable"
        scrollButtons={false}
      >
        {routes
          .filter((route) =>
            process.env.REACT_APP_TYPE === "bankeeonline" &&
            userInfo?.user.school_plan !== UserSchoolPlan.SCHOOL
              ? route.tabName !== "coteachers"
              : route
          )
          .map((route) => (
            <Tab
              key={route.labelKey}
              label={t(route.labelKey)}
              component={NavLink}
              to={route.path.replace(":classId", classId)}
              state={{ from: location.pathname }}
              value={route.tabName}
              onClick={() => {
                if (route.tabName !== "coteachers") {
                  mixpanel.track("main_head_tab", {
                    event_name: route.tabName,
                  })
                }
              }}
            />
          ))}
        {data?.data.is_banker && (
          <Tab
            value="banker"
            to={RoutePath.CLASS_BANKER.replace(":classId", classId)}
            label={t("layout.banker")}
            component={NavLink}
          />
        )}
        {data?.data.is_store_clerk && (
          <Tab
            value="storeClerk"
            to={RoutePath.CLASS_STORECLERK.replace(":classId", classId)}
            label={t("layout.storeClerk")}
            component={NavLink}
          />
        )}
        {data?.data.is_hr_representative && (
          <Tab
            value="hrRepresentative"
            to={RoutePath.CLASS_HR.replace(":classId", classId)}
            label={t("layout.hrRepresentative")}
            component={NavLink}
          />
        )}
      </Tabs>
    </Box>
  )
}

export default NavigationTabs
