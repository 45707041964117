import React from "react"

import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import TableView from "./tableView"

const Banker = () => {
  const { t } = useTranslation()
  return (
    <Stack>
      <Typography>{t("layout.sendTransactions")}</Typography>
      {/*{displayMode === "grid"} && <GridView />*/}
      <TableView />
    </Stack>
  )
}
export default Banker
