export enum Endpoint {
  USER_ME = "/user/me",
  STUDENT_ME = "/student/me",
  PHOTOS = "/photos",
  TEACHER = "/teacher",
  TEACHER_ME = "/teacher/me",
  TEACHER_FINALIZE = "/teacher/finalize",
  TEACHER_LOGIN_ACCESS_TOKEN = "/teacher/login/access-token",
  CLASSES = "/classes",
  DASHBOARD_OVERVIEW = "/dashboard/overview",
  DASHBOARD_TRANSACTIONS = "/dashboard/transactions",
  DASHBOARD_TODO = "/dashboard/todo",
  DASHBOARD_TODO_STOREUPDATED = "/dashboard/todo/store-updates",
  STUDENTS_SEARCH = "/students/search",
  STUDENT_TRANSACTION = "/students/ID/transactions",
  RETRIEVE_STUDENT = "/students",
  STUDENT_ID_CLASSES = "/students/ID/classes",
  STUDENT_POSSIBLE_JOBS = "/students/ID/jobs",
  STUDENT_HIRE = "/classes/CLASSID/jobs/JOBID/hire",
  STUDENT_FIRE = "/classes/CLASSID/jobs/JOBID/fire",
  STUDENT_BALANCE = "/student/balance",
  STUDENT_JOBS = "/student/jobs",
  SCHOOLS = "/schools",
  TEACHER_PASSWORD_RECOVERY = "/teacher/password-recovery",
  TEACHER_PASSWORD_RESET = "/teacher/reset-password",
  TEACHER_PASSWORD_CHANGE = "/teacher/change-password",
  COUNTRIES = "/countries",
  TEACHER_LOGIN_GOOGLE = "/teacher/login/google",
  STORE_TRANSACTIONS_ACCEPT = "/store_transactions/accept",
  STORE_TRANSACTIONS_REJECT = "/store_transactions/reject",
  STORE_TRANSACTIONS_ID_ACCEPT = "/store_transactions/ID/accept",
  STORE_TRANSACTIONS_ID_REJECT = "/store_transactions/ID/reject",
  ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_ACCEPT = "/ID/helper/store-clerk/store-transactions/accept",
  ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_REJECT = "/ID/helper/store-clerk/store-transactions/reject",
  ID_HELPER_STORE_CLERK_STORE_TRANSACTIONS_STOREID_REJECT = "/ID/helper/store-clerk/store-transactions/STOREID/reject",
  CLASSES_ID_CANDIDATES = "/classes/ID/candidates",
  CLASSES_STORE_TRANSACTIONS = "/classes/ID/store_transactions",
  CLASSES_HELPER_STORE_CLERK_STORE_TRANSACTIONS = "/classes/ID/helper/store-clerk/store-transactions",
  CLASSES_ID_TRANSACTIONS = "/classes/ID/transactions",
  CLASSES_ID_HELPER_BANKER_TRANSACTIONS = "/classes/ID/helper/banker/transactions",
  CLASSES_FINES = "/classes/ID/fines",
  CLASSES_BONUSES = "/classes/ID/bonuses",
  CLASSES_EXPENSES = "/classes/ID/expenses",
  CLASSES_STORE = "/classes/ID/store",
  CLASSES_ID_HELPER_STORE_CLERK_STORE = "/classes/ID/helper/store-clerk/store",
  CLASSES_STORE_ITEMS = "/classes/ID/store/items",
  CLASSES_ID_HELPER_STORE_CLERK_STORE_ITEMS = "classes/ID/helper/store-clerk/store/items",
  CLASSES_ID_STUDENTS = "/classes/ID/students",
  CLASSES_COPY_SETTINGS = "/classes/copy-settings",
  CLASSES_COPY_SETTINGS_CHECK = "/classes/copy-settings-check",
  IMAGES = "/images",
  CLASSES_JOBS = "/classes/ID/jobs",
  STUDENTS_MATH_MANAGEMENT = "/students/math-management",
  STUDENTS_LOGIN_ACCESS_TOKEN = "/students/login/access-token",
  STUDENTS_SIGN_UP = "/students/sign-up",
  STUDENT_TRANSACTIONS_PENDING = "/student/transactions/pending",
  STUDENT_CLASSES = "/student/classes",
  STUDENT_CLASSES_ID = "/student/classes/ID",
  STUDENT_ID_STORE_ITEMS = "/student/ID/store/items",
  STUDENT_ID_STORE_PURCHASE = "/student/ID/store/purchase",
  STUDENTS_ID_BALANCE_CHARTS = "/students/ID/balance-charts",
  STUDENTS_FINALIZE = "/students/finalize",
  TRANSACTIONS_ID_BOOK = "/transactions/ID/book",
  CLASSES_ID_JOBS_CLEAN = "/classes/ID/jobs/clean",
  STUDENT_JOB_APPLICATIONS = "/student/job_applications",
  CREATE_CV = "/cv",
  STUDENT_TRANSACTIONS_BOOKED = "/student/transactions/booked",
  CLASSES_JOBS_REJECT = "/classes/CLASSID/jobs/JOBID/reject",
  JOB_APPLICATIONS = "/job_applications",
  SCHOOL_CLASSES_ID_COTEACHERS = "/school/classes/ID/co-teachers/",
  SCHOOL_CLASSES_CLASSID_COTEACHERS_TEACHERID = "/school/classes/CLASSID/co-teachers/TEACHERID",
  SCHOOL_CLASSES_ID_COTEACHERS_POSSIBLE = "/school/classes/ID/co-teachers/possible",
  STUDENTS_ID_JOBS_EXPORT = "/students/ID/jobs/export",
  STUDENTS_ID_TRANSACTIONS_EXPORT = "/students/ID/transactions/export",
  STUDENTS_ID_SAVINGS = "/students/ID/savings",
  STUDENTS_ID_GOALS = "/students/ID/goals",
  CLASSES_ID_STUDENTS_EXPORT = "/classes/ID/students/export",
  CLASSES_ID_STORE_TRANSACTIONS_EXPORT = "/classes/ID/store_transactions/export",
  CLASSES_ID_JOBS_EXPORT = "/classes/ID/jobs/export",
  SCHOOL_TEACHERS = "/school/teachers/",
  SCHOOL_TEACHERS_ID_ACCEPT = "/school/teachers/ID/accept",
  SCHOOL_TEACHERS_ID_REJECT = "/school/teachers/ID/reject",
  SCHOOL_INVITATIONS = "/school/invitations/",
  SCHOOL_INVITATIONS_ID = "/school/invitations/ID",
  TEACHER_REGISTRATION_DATA_ID = "/teacher/registration-data/ID",
  PAYMENTS_CHECKOUT_SESSIONS = "/payments/checkout/sessions",
  TEACHER_DOWNGRADE = "/teacher/downgrade",
  STUDENTS_REGISTER_GOOGLE = "/students/register/google",
  STUDENTS_LOGIN_GOOGLE = "/students/login/google",
  STUDENTS_ASSIGN = "/students/assign-google-account",
  CLASSES_ID_STUDENTS_IMPORT = "/classes/ID/students/import",
  CLASSES_ID_STUDENTS_BALANCES_RESET = "/classes/ID/students/balances/reset",
  ASYNCTASKS_ID_STATE = "/async-tasks/ID/state",
  CLASSES_ID_CLASSSTUDENTS = "/classes/ID/class-students",
  STUDENTS_ID_ALL_TRANSACTIONS = "/students/ID/all-transactions",
  TEACHER_TRANSACTIONS = "/teacher/transactions/",
  TEACHER_TRANSACTIONS_EXPORT = "/teacher/transactions/export",
  TEACHER_CHOOSE_SCHOOL = "/teacher/choose-school",
  STUDENT_TRANSACTIONS_ALL = "/student/transactions/all",
  STUDENT_SAVINGS = "/student/savings",
  STUDENT_ID_GOALS = "/student/ID/goals",
  STUDENT_GOALS_ID_ADD_FUNDS = "/student/goals/ID/add-funds",
  STUDENT_ID_GOALS_ID = "/student/ACCOUNTID/goals/GOALID",
  STUDENT_BALANCE_CHARTS = "/student/balance-charts",
  STUDENT_ACCOUNT_TRANSFER = "/student/account-transfer",
  STUDENT_SAVINGS_SNAPSHOT = "/student/savings-snapshot",
  STUDENT_GOALS_ID_WITHDRAW_FUNDS = "/student/goals/ID/withdraw-funds",
  STUDENT_GOALS_COMPLETED = "/student/goals/completed",
  TEACHER_CONFIRM_MAIL = "/teacher/confirm-email",
  STUDENT_ACCOUNT_TRANSACTIONS = "/student/account-transactions",
  STUDENT_SAVINGS_CHARTS_ID = "/student/savings-charts/ID",
  CLASSES_ID_STUDENT_HELPERS = "/classes/ID/student-helpers",
  CLASSES_ID_HELPER_BANKER_STUDENTS = "/classes/ID/helper/banker/students",
}
